// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._lZfN{color:#6f7ea2;font-size:15px;font-size:1.5rem;margin:5rem 0 2.5rem}", ""]);
// Exports
exports.locals = {
	"dataSourcesIntro": "_lZfN"
};
module.exports = exports;
