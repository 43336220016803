// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1FIKX{color:#6f7ea2;font-size:15px;font-size:1.5rem;margin:5rem 0 2.5rem}._1iZNx{padding:2rem;background:#fff;border:1px solid #e0e8f2;border-radius:8px;margin-bottom:1rem}._14Jxe{padding:0 2rem;border:none;background:rgba(0,0,0,0)}._298Jr{min-height:40px;min-height:4rem}._3ptQP{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;width:250px;width:25rem}._1aQN2{font-size:20px;font-size:2rem;font-weight:700;line-height:1}._2_T8d{margin-top:.5rem;font-weight:400;color:#6f7ea2}._2aZEf{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:40px;width:4rem;height:40px;height:4rem;padding:5px;background:#fff;border:1px solid #e0e8f2;border-radius:8px;overflow:hidden}._2BAul{width:auto;max-height:100%}.mNnId{display:block;color:#fff;font-size:18px;font-size:1.8rem;font-weight:700;line-height:1.8;height:34px;height:3.4rem}.n-rD5{height:0;overflow:hidden}.n-rD5 .tag{font-size:13px;font-size:1.3rem;text-transform:uppercase;color:#6f7ea2;margin:0}.n-rD5 tr:last-child{border-bottom:none}._1q5Kg{display:block;height:15px;height:1.5rem;width:20px;width:2rem;margin-right:1.2rem;fill:#30a8ff}", ""]);
// Exports
exports.locals = {
	"dataSourcesIntro": "_1FIKX",
	"dataSource": "_1iZNx",
	"dataSource--standalone": "_14Jxe",
	"dataSource__headerRow": "_298Jr",
	"dataSource__status": "_3ptQP",
	"dataSource__name": "_1aQN2",
	"dataSource__info": "_2_T8d",
	"dataSource__logo": "_2aZEf",
	"dataSource__logo__img": "_2BAul",
	"dataSource__logo__initials": "mNnId",
	"dataSource__capabilities": "n-rD5",
	"dataSource__capability__icon": "_1q5Kg"
};
module.exports = exports;
