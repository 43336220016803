import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import messages from '../../messages';
import ExternalDataSources from '../ExternalDataSources';
import ContourCloudInfo from './ContourCloudInfo';
import styles from './PwdSettings.pcss';


const PwdSettings: FC = () => {
  useStyles(styles);

  return (
    <section>
      <h2 className="section__header"><FormattedMessage {...messages.headers.dataSourcesSettings} /></h2>
      <ContourCloudInfo />
      <p className={styles.dataSourcesIntro}>
        <FormattedMessage {...messages.intros.dataSourcesSettings} />
      </p>
      <ExternalDataSources />
    </section>
  );
};

export default PwdSettings;
